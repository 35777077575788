@import "./ui-kit/src/components/CustomDatePicker/customDatePickerStyles.css";
@import "./fonts/Cairo/cairo.css";

@font-face {
	font-family: 'Brother-1816';
	src: url('./fonts/Brother1816Printed-Book.eot');
	src: local('Brother 1816'), local('Brother-1816'),
		url('./fonts/Brother1816Printed-Book.eot?#iefix') format('embedded-opentype'),
		url('./fonts/Brother1816Printed-Book.woff') format('woff'),
		url('./fonts/Brother1816Printed-Book.ttf') format('truetype');
	font-style: normal;
}

html {
	font-size: 62.5%;
}

input[type='number'] {
	-moz-appearance:textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

body {
  background-color: #F6F6F7;
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  font-family: Brother-1816;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
	height: 100%;
	width: 100%;
}

html {
	font-size: 62.5%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
